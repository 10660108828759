// Import our custom theme
@import 'sass/app-theme.scss';
@import 'sass/fonts.scss';
@import 'sass/cartus-palette.scss';
@import 'sass/app.scss';
@import 'sass/common-styles.scss';
@import 'sass/dialog.scss';
@import 'sass/snackbar.scss';
@import 'sass/mat-table.scss';
@import 'sass/mat-table-column.scss';
@import 'sass/breadcrum.scss';
@import 'sass/mixin.scss';
@import 'sass/cartus-palette.scss';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@mixin custom-components-theme($theme, $typography, $cartus-palette) {
    @include app($theme, $typography, $cartus-palette);
}

@include angular-material-theme($app-theme);
.ccu-theme {
    // use our theme with angular-material-theme mixin
    // @include angular-material-theme($app-theme);
    // include custom components theme mixin once per theme class
    @include custom-components-theme($app-theme, $typography, $cartus-palette);
}

html,
body {
    height: 100%;
    box-sizing: border-box;
    // background-color: map-get($cartus-palette, cartus_nav_tile);;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-width: 331px;
    font-family: 'Avenir-Book', sans-serif;
    font-size: 16px;
    color: map-get($cartus-palette, 'cartus-gray');
    -moz-osx-font-smoothing: grayscale;
    /* Fix blurry font for Firefox Mac users connected to external displays */
}

.mat-card {
    font-family: 'Avenir-Book', sans-serif;
    &:focus {
        outline: 2px solid map-get($cartus-palette, cartus_dark_accent_blue);
    }
}

*[fxlayoutgap]>*:last-child {
    margin-right: 0;
    /* Fix for flex last column in a row having a right margin */
}


/* width */

::-webkit-scrollbar {
    width: 5px;
    height: 3px;
}


/* Track */

 ::-webkit-scrollbar-track {
    background: transparent;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 20px;
}

button:focus {
    outline: 2px solid map-get($cartus-palette, cartus_dark_accent_blue);
    border-radius: 10px;
}

button, *[role='button'], a {
    cursor: pointer !important;
}

input[type='number'] {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
}

//Custom material colors
.mat-form-field.mat-focused .mat-form-field-label,
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: map-get($cartus-palette, "cartus_dark_accent_blue");
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: map-get($cartus-palette, "cartus_dark_accent_blue");
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: map-get($cartus-palette, "cartus_primary_blue") !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: map-get($cartus-palette, "cartus_primary_blue") !important;
}
.disabled-check.mat-checkbox-checked.mat-accent .mat-checkbox-background
 {
    background-color: map-get($cartus-palette, "cartus_disabled_gray");
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: map-get($cartus-palette, "cartus_primary_blue") !important;
}

.mat-icon.grey-color {
    color: map-get($cartus-palette, cartus_bright_grey);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 16px;
}

.mat-form-field .mat-input-element,
.mat-form-field .mat-select-content,
.mat-form-field .mat-select-value {
    padding-bottom: 7px;
}

.mat-form-field.mat-focused .mat-form-field-label,
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: map-get($cartus-palette, cartus_bright_grey);
}

//styles for last div of timeline
.last-div {
    .timeline-wrapper {
        .circle-wrapper {
            .circle {
                &::after {
                    display: none;
                }
            }
        }
    }
}

.next {
    @include button-style(160px, 2px solid map-get($cartus-palette, cartus_primary_blue), map-get($cartus-palette, cartus_primary_blue), 0 24px 0 0, map-get($cartus-palette, cartus_nav_tile), 16px)
}

.next-disabled {
    @include button-style(160px, 2px solid #cccccc, #cccccc, 0 24px 0 0, map-get($cartus-palette, cartus_nav_tile), 16px)
}

.previous {
    @include button-style(160px, 2px solid map-get($cartus-palette, cartus_primary_blue), transparent, 0 48px 0 0, map-get($cartus-palette, cartus_primary_blue), 16px);
}

.submit {
    @include button-style(160px, 2px solid map-get($cartus-palette, cartus_primary_blue), map-get($cartus-palette, cartus_primary_blue), 0 24px 0 0, map-get($cartus-palette, cartus_nav_tile), 16px)
}

.submit-disabled {
    @include button-style(160px, 2px solid #cccccc, #cccccc, 0 24px 0 0, map-get($cartus-palette, cartus_nav_tile), 16px)
}

.mat-form-field-infix {
    padding: 10px 0;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    color: map-get($cartus-palette, cartus_primary_dark_brown);
    font-size: 12px;
    font-family: 'Avenir-Medium', sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
    font-family: 'Avenir-Medium', sans-serif;
    font-size: 16px;
    color: map-get($cartus-palette, cartus_primary_dark_brown);
}

.mat-option-text,
.mat-option {
    font-size: 16px;
}

.mat-form-field {
    .mat-input-element,
    .mat-select-content,
    .mat-select-value {
        color: map-get($cartus-palette, cartus_bright_grey) !important;
        font-family: 'Avenir-Medium', sans-serif;
        font-size: 16px;
    }
}

.mat-input-element {
    caret-color: map-get($cartus-palette, cartus_primary_blue);
    text-overflow: ellipsis;

}

.mat-form-field.mat-form-field-invalid,
 .mat-form-field-label {
    font-family: 'Avenir-Book';
}

.mat-form-field-appearance-legacy .mat-form-field-label{
    font-family: 'Avenir-Book';
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.275em) scale(1) perspective(100px) translateZ(0.001px) !important;
}

::ng-deep .custom-dialog {
    .mat-dialog-container {
        padding: 20px 0px 44px 26px;
        position: relative;
        border-radius: 10px;
    }
}
::ng-deep .activate-benefit-dialog{
    .mat-dialog-container {
        border-radius: 10px;
        padding:13px;
    }
}

::ng-deep #dialog2 {
    .mat-dialog-content {
        text-align: center;
        padding: 0;
        border-radius: 10px;
        .button-wrapper {
            margin-top: 20px;
            .delete-card {
                border: 2px solid map-get($cartus-palette, cartus_primary_blue);
                border-radius: 5px;
                color: map-get($cartus-palette, cartus_primary_blue);
                font-family: 'Avenir-Medium', sans-serif;
                margin-right: 10px;
            }
            .cancel {
                background-color: map-get($cartus-palette, cartus_primary_blue);
                border-radius: 5px;
                color: #ffffff;
                border: 2px solid map-get($cartus-palette, cartus_primary_blue);
                font-family: 'Avenir-Medium', sans-serif;
            }
        }
    }
}

::ng-deep .complete-family-custom-dialog {
    .mat-dialog-container {
        padding-top: 22px;
    }
}

//mat menu style for profile-dropdown in header//
::ng-deep.cdk-overlay-pane .mat-menu-panel {
    max-width: 100% !important;
    .mat-menu-content {
        box-shadow: 0px 3px 5px map-get($cartus-palette, cartus_card_shadow);
        button.mat-menu-item {
            color: map-get($cartus-palette, cartus_dark_accent_blue);
            font-size: 16px;
            font-family: 'Avenir-Medium', sans-serif;
            &:focus {
                outline: none;
            }
            &:hover {
                background-color: #FAFADC;
            }
        }
        .menu-image {
            margin-right: 20px;
            width: 30px;
            object-fit: scale-down;
        }
    }
}

//sidenav content background
.mat-drawer-content {
    background-color: map-get($cartus-palette, cartus_white_smoke);
}

//mobile title styles
.active-title {
    color: map-get($cartus-palette, cartus_primary_blue);
    font-size: 20px;
    font-family: 'Avenir-Medium', sans-serif;
    margin: 25px 0 13px 0;
    mat-icon {
        padding-left: 5px;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.mat-bottom-sheet-container-large {
    border-radius: 16px 16px 0px 0px!important;
}

.mat-bottom-sheet-container {
    width: 90vw!important;
}

@media (min-width:768px) and (max-width:1024px) and (orientation:portrait) {
     ::ng-deep .custom-dialog {
        max-width: 92vw !important;
        overflow: auto !important;
        .mat-dialog-container {
            padding: 28px 14px 22px 20px;
        }
        .mat-dialog-content {
            max-height: unset;
        }
    }
}

@media (min-width:320px) and (max-width:767px) {
     ::ng-deep .custom-dialog {
        max-width: 92vw !important;
        overflow: auto !important;
        .mat-dialog-container {
            padding: 28px 14px 22px 20px;
        }
        .mat-dialog-content {
            max-height: unset;
            margin: 0;
            padding: 0 !important;
            overflow: unset;
        }
    }
     ::ng-deep .complete-family-custom-dialog {
        max-width: 89vw !important;
        overflow: auto !important;
        .mat-dialog-container {
            padding: 14px 14px 22px 24px;
        }
    }
}

@media screen and (max-width: 900px) {
    .mat-bottom-sheet-container {
        border-radius: 16px 16px 0px 0px!important;
        min-width: 90vw !important
    }
}

// Utility Classes
.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

.d-inline {
    display: inline;
}

.d-relative {
    position: relative;
}

.d-absolute {
    position: absolute;
}

.pointer {
    cursor: pointer !important;
}

// Skeleton Loading
.skeleton-box,
.skeleton-box-rounded,
.skeleton-box-dark,
.skeleton-box-dark-rounded {
    position: relative;
    overflow: hidden;
    box-shadow: none !important;
    &::before {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #e8e8e8;
        z-index: 1;
    }
    &::after {
        content: '';
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient( 90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
        animation: shimmer 2s infinite;
    }
    @keyframes shimmer {
        100% {
          transform: translateX(100%);
        }
      }
}

.skeleton-box-rounded {
    border-radius: 16px;
}

.skeleton-box-dark {
    background-color: #d6d6d6
}

.skeleton-box-dark-rounded {
    border-radius: 16px;
}

.mat-simple-snackbar-action {
    color: #fff !important;
}


.mat-simple-snackbar {
    font-family: 'Avenir-Book' !important;
    font-size: 14px;
}


.toaster{
    font-family: 'Avenir-Book', sans-serif;
    font-weight: bold;
    color: map-get($cartus-palette, cartus_white_smoke) !important;
    font-size: 20px !important;
    text-align: center;
    .mat-simple-snackbar-action {
        color: map-get($cartus-palette, cartus_white_smoke) !important;
        font-size: 20px !important;
        font-weight: bolder;
        background: map-get($cartus-palette, cartus_primary_blue) !important;
        border-radius: 10px;
    }
}
.backdropBackground {
    background-color:#333333;
    opacity:0.8 !important;
}


.owl-theme .owl-nav [class*=owl-]:hover {
    color: #1670ef !important;
    background: none !important;
}

.owl-theme .owl-nav{
    position: relative;
    bottom: 130px;
}

.owl-theme .owl-nav [class*=owl-] {
    color: #1670ef !important;
    background: none !important;
    font-size: 36px;
    width: 20px;
}

.owl-prev{
    position: absolute !important;
    left: 0px !important;
}

.owl-next{
    position: absolute !important;
    right: 0px !important;
}

.mat-icon{
    overflow: visible !important;
}
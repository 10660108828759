@import './mixins/media-mixins.scss';
//mixins
@mixin button-style($width, $border, $bgcolor, $margin, $color, $fontSize) {
    width: $width;
    border: $border;
    font-size: $fontSize;
    background-color: $bgcolor;
    margin: $margin;
    text-align: center;
    padding: 9px 0;
    color: $color;
    border-radius: 4px;
    font-family: 'Avenir-Medium', sans-serif;
    cursor: pointer;
}

// @mixin background-image {
//     background-repeat: no-repeat;
//     border: none;
// }
@mixin dashboard-btn {
    background-color: map-get($cartus-palette, cartus_primary_blue);
    color: #ffffff;
    border-radius: 5px;
    padding: 7px 20px;
    width: 136px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    &:disabled {
        opacity: 0.6;
    }
}

@mixin rounded-filled-btn {
    color: var(--Light-Primary-Contrast, #FFF);
    border-radius: 16px;
    padding: 8px 20px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.46px;
    width: 160px;
    background-color:  #2C77D5;
    border: none;
    &:disabled {
        opacity: 0.6;
    }
}

@mixin outlined-button {
    padding: 8px 22px;
    border-radius: 100px;
    border: 2px solid var(--Primary-Blue-Alt, #2C77D5);
    line-height: 2em;
    &:disabled {
        opacity: 0.6;
    }
}
